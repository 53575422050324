import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BlogList from "../components/BlogList";

export default function CategoryTemplate({ data, pageContext }) {
  return (
    <Layout>
      <BlogList
        title={pageContext.category}
        edges={data.allMarkdownRemark.edges}
      />
    </Layout>
  );
}

export const query = graphql`
  query($category: String!) {
    site {
      siteMetadata {
        siteURL
        nickname
        avatar
        twitter
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          contentKey: { eq: "blog" }
          categories: { in: [$category] }
        }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            categories
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              publicURL
            }
            date(formatString: "MMM D, YYYY")
          }
          fields {
            slug
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;
